<template>
    <div class="login-container login text-center animated flipInX">
      <div class="container-content">
        <transition name="slide-fade" appear>
          <form @submit.prevent="handleSubmit">
            <img src="@/assets/empresa.svg" alt="User" class="img-login">
            <h5 class="text-whitesmoke">PORTAL DE PROVEEDORES</h5>
            <p class="text-whitesmoke">Restaurar contraseña</p>
            <div class="form-group" :class="{error: validation.hasError('email')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'envelope', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Email" v-model="email">
                <span class="icon" :title="validation.firstError('email')" v-if="validation.hasError('email')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <button type="submit" class="form-button btn-block mb-2">
              <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>Envíar correo de recuperación
            </button>
            <router-link class="text-gray" :to="'/auth/login'">
              <small><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }"/> VOLVER A INICIO DE SESIÓN</small>
            </router-link>
          </form>
        </transition>
      </div>
    </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'login',
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
  },
  data() {
    return {
      email: '',
    };
  },
  validators: {
    email(value) { return Validator.value(value).required('Campo requerido').email('Formato de correo no válido').lengthBetween(5, 100, 'Longitud debe ser entre 5 y 6 caracteres'); },
  },
  mounted() {
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.$store.dispatch('user/FORGOT_PASSWORD', { payload: { email: this.email } });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
